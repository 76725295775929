import { Inject, Injectable, Injector } from '@angular/core';


import { UccLabels_ucc_label_costcoService } from './UccLabels.report.index';
import { UccLabels_ucc_label_walmartService } from './UccLabels.report.index';

@Injectable({ providedIn: 'root' })
export class UccLabels_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public UccLabels: UccLabels_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ucc_label_costco: UccLabels_ucc_label_costcoService;
  public get ucc_label_costco(): UccLabels_ucc_label_costcoService {
    if(!this._ucc_label_costco) {
      this._ucc_label_costco = this.injector.get(UccLabels_ucc_label_costcoService);
    }
    return this._ucc_label_costco;
  }
  private _ucc_label_walmart: UccLabels_ucc_label_walmartService;
  public get ucc_label_walmart(): UccLabels_ucc_label_walmartService {
    if(!this._ucc_label_walmart) {
      this._ucc_label_walmart = this.injector.get(UccLabels_ucc_label_walmartService);
    }
    return this._ucc_label_walmart;
  }
}

