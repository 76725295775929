import { Inject, Injectable, Injector }from '@angular/core';

import { PrintNode_DatasourceService } from './PrintNode.datasource.index';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { UccLabels_DatasourceService } from './UccLabels.datasource.index';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Inventory_DatasourceService } from './Inventory.datasource.index';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';
import { Reports_DatasourceService } from './Reports.datasource.index';

import { app_ds_get_inventory_by_project_lookupService } from './app.datasource.index';
import { app_ds_get_warehousesService } from './app.datasource.index';

@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.PrintNode = this.injector.get(PrintNode_DatasourceService);
    this.Notifications = this.injector.get(Notifications_DatasourceService);
    this.UccLabels = this.injector.get(UccLabels_DatasourceService);
    this.Invoices = this.injector.get(Invoices_DatasourceService);
    this.Inventory = this.injector.get(Inventory_DatasourceService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_DatasourceService);
    this.Reports = this.injector.get(Reports_DatasourceService);
  }

    public PrintNode: PrintNode_DatasourceService;
    public Notifications: Notifications_DatasourceService;
    public UccLabels: UccLabels_DatasourceService;
    public Invoices: Invoices_DatasourceService;
    public Inventory: Inventory_DatasourceService;
    public FootPrintApiManager: FootPrintApiManager_DatasourceService;
    public Reports: Reports_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_inventory_by_project_lookup: app_ds_get_inventory_by_project_lookupService;
  public get ds_get_inventory_by_project_lookup(): app_ds_get_inventory_by_project_lookupService {
    if(!this._ds_get_inventory_by_project_lookup) {
      this._ds_get_inventory_by_project_lookup = this.injector.get(app_ds_get_inventory_by_project_lookupService);
    }
    return this._ds_get_inventory_by_project_lookup;
  }
  private _ds_get_warehouses: app_ds_get_warehousesService;
  public get ds_get_warehouses(): app_ds_get_warehousesService {
    if(!this._ds_get_warehouses) {
      this._ds_get_warehouses = this.injector.get(app_ds_get_warehousesService);
    }
    return this._ds_get_warehouses;
  }
}

