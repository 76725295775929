import { Injectable } from '@angular/core';
import { MissingTranslationHandler, TranslateCompiler, TranslateParser, TranslateService, TranslateStore } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Language, LocalizationService, LocalizationSettings } from './localization.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PrintNode_LocalizationService } from './PrintNode.localization.service';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { UccLabels_LocalizationService } from './UccLabels.localization.service';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Inventory_LocalizationService } from './Inventory.localization.service';
import { FootPrintApiManager_LocalizationService } from './FootPrintApiManager.localization.service';
import { Reports_LocalizationService } from './Reports.localization.service';

@Injectable({
  providedIn: 'root'
})
export class app_LocalizationService
  extends LocalizationService<{  }> {

  public app: app_LocalizationService = this;

  public override get settings(): LocalizationSettings {
    return {"default":"","languages":[]}
  }
  
  private _referencedServices: LocalizationService<any>[] = [];

  constructor(
    httpClient: HttpClient,
    compiler: TranslateCompiler,
    parser: TranslateParser,
    missingHandler: MissingTranslationHandler,
    public PrintNode: PrintNode_LocalizationService,
    public Notifications: Notifications_LocalizationService,
    public UccLabels: UccLabels_LocalizationService,
    public Invoices: Invoices_LocalizationService,
    public Inventory: Inventory_LocalizationService,
    public FootPrintApiManager: FootPrintApiManager_LocalizationService,
    public Reports: Reports_LocalizationService
    ) {
    super(
      new TranslateService(
        new TranslateStore(),
        new TranslateHttpLoader(httpClient, '../assets/i18n/app/', '.json'),
        compiler,
        parser,
        missingHandler,
        true,
        true,
        false,
        ''));

    this._referencedServices.push(this.PrintNode);

    this._referencedServices.push(this.Notifications);

    this._referencedServices.push(this.UccLabels);

    this._referencedServices.push(this.Invoices);

    this._referencedServices.push(this.Inventory);

    this._referencedServices.push(this.FootPrintApiManager);

    this._referencedServices.push(this.Reports);
    
    this.initialize();

  }

  public override setLanguage(lang: Language): void{
    super.setLanguage(lang);
  
    for (let i of this._referencedServices){
      i.setLanguage(lang);
    }
  }
}