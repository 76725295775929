import { Inject, Injectable, Injector } from '@angular/core';


import { UccLabels_get_user_defined_field_namesService } from './UccLabels.flow.index';
import { UccLabels_get_user_defined_field_valuesService } from './UccLabels.flow.index';
import { UccLabels_ucc_create_labelsService } from './UccLabels.flow.index';

import { $frontendTypes } from './UccLabels.frontend.types'

@Injectable({ providedIn: 'root' })
export class UccLabels_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public UccLabels: UccLabels_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _get_user_defined_field_names: UccLabels_get_user_defined_field_namesService;
  public async get_user_defined_field_names(inParams: { EntityTypes?: string[] }): Promise< { UserDefinedFields?: { EntityType?: string, UserDefinedFields?: { Name?: string, Type?: string }[] }[], messages?: any[] }> {
    if(!this._get_user_defined_field_names) {
      this._get_user_defined_field_names = this.injector.get(UccLabels_get_user_defined_field_namesService);
    }
    return this._get_user_defined_field_names.run(inParams);
  }
   
   

   
 
  private _get_user_defined_field_values: UccLabels_get_user_defined_field_valuesService;
  public async get_user_defined_field_values(inParams: { UserDefinedFieldNames?: string[], EntityType?: string, EntityKeys?: { Name?: string, Value?: any[] }[] }): Promise< { outputs?: { EntityKeys?: { Name?: string, Value?: string }[], UserDefinedFieldValues?: { Name?: string, Value?: string }[] }[], messages?: any[] }> {
    if(!this._get_user_defined_field_values) {
      this._get_user_defined_field_values = this.injector.get(UccLabels_get_user_defined_field_valuesService);
    }
    return this._get_user_defined_field_values.run(inParams);
  }
   
   

   
 
  private _ucc_create_labels: UccLabels_ucc_create_labelsService;
  public async ucc_create_labels(inParams: { shipment_id?: number, order_id?: number, quantity?: number }): Promise< { Labels?: { ShipmentId?: number, ShippingContainerId?: number, ShippingContainerLookupCode?: string }[] }> {
    if(!this._ucc_create_labels) {
      this._ucc_create_labels = this.injector.get(UccLabels_ucc_create_labelsService);
    }
    return this._ucc_create_labels.run(inParams);
  }
   
   

   
}
