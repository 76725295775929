import { Inject, Injectable, Injector } from '@angular/core';

import { PrintNode_FlowService } from './PrintNode.flow.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { UccLabels_FlowService } from './UccLabels.flow.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Inventory_FlowService } from './Inventory.flow.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';
import { Reports_FlowService } from './Reports.flow.index';

import { app_custom_get_report_data_flowService } from './app.flow.index';
import { app_custom_print_ucc_labelsService } from './app.flow.index';
import { app_update_email_ruleService } from './app.flow.index';

import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.PrintNode = this.injector.get(PrintNode_FlowService);
    this.Notifications = this.injector.get(Notifications_FlowService);
    this.UccLabels = this.injector.get(UccLabels_FlowService);
    this.Invoices = this.injector.get(Invoices_FlowService);
    this.Inventory = this.injector.get(Inventory_FlowService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_FlowService);
    this.Reports = this.injector.get(Reports_FlowService);
  }

    public PrintNode: PrintNode_FlowService;
    public Notifications: Notifications_FlowService;
    public UccLabels: UccLabels_FlowService;
    public Invoices: Invoices_FlowService;
    public Inventory: Inventory_FlowService;
    public FootPrintApiManager: FootPrintApiManager_FlowService;
    public Reports: Reports_FlowService;
  public app: app_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _custom_get_report_data_flow: app_custom_get_report_data_flowService;
  public async custom_get_report_data_flow(inParams: { package_name: string, report_name: string, inputs?: any }): Promise< { report_data?: Blob }> {
    if(!this._custom_get_report_data_flow) {
      this._custom_get_report_data_flow = this.injector.get(app_custom_get_report_data_flowService);
    }
    return this._custom_get_report_data_flow.run(inParams);
  }
   
   

   
 
  private _custom_print_ucc_labels: app_custom_print_ucc_labelsService;
  public async custom_print_ucc_labels(inParams: { order_id?: number, shipment_id?: number, shipping_container_id?: number, copies?: number, printer_name: string }): Promise<void> {
    if(!this._custom_print_ucc_labels) {
      this._custom_print_ucc_labels = this.injector.get(app_custom_print_ucc_labelsService);
    }
    return this._custom_print_ucc_labels.run(inParams);
  }
   
   

   
 
  private _update_email_rule: app_update_email_ruleService;
  public async update_email_rule(inParams: { days_to_subtract?: number, rule_id?: string }): Promise< { results?: any[] }> {
    if(!this._update_email_rule) {
      this._update_email_rule = this.injector.get(app_update_email_ruleService);
    }
    return this._update_email_rule.run(inParams);
  }
   
   

   
}
