import { Inject, Injectable, Injector }from '@angular/core';


import { Reports_custom_ds_get_order_ship_to_account_vs_order_addressService } from './Reports.datasource.index';
import { Reports_custom_ds_inventory_by_project_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_invoice_get_billingtask_detailsService } from './Reports.datasource.index';
import { Reports_custom_ds_invoice_get_order_detailsService } from './Reports.datasource.index';
import { Reports_custom_ds_invoice_get_owner_detailsService } from './Reports.datasource.index';
import { Reports_custom_ds_invoice_header_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_invoice_lines_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_invoice_lines_w_workorder_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_bol_get_carrier_detailsService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_bol_get_loadcontainer_detailsService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_bol_get_pallet_detailsService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_bol_get_shipandpymtterm_detailsService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_details_bill_of_lading_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_details_bill_of_lading_report_advancedService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_header_bill_of_lading_reportService } from './Reports.datasource.index';
import { Reports_custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1Service } from './Reports.datasource.index';
import { Reports_custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentIdService } from './Reports.datasource.index';
import { Reports_custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentId_completedService } from './Reports.datasource.index';
import { Reports_custom_salesorders_ds_get_order_account_address_by_orderIdService } from './Reports.datasource.index';
import { Reports_custom_salesorders_ds_get_order_address_by_orderId_top1Service } from './Reports.datasource.index';
import { Reports_custom_salesorders_ds_get_order_owner_contact_top1Service } from './Reports.datasource.index';
import { Reports_custom_salesorders_ds_get_order_ship_to_account_vs_order_addressService } from './Reports.datasource.index';
import { Reports_custom_salesorders_ds_get_order_signature_attachmentService } from './Reports.datasource.index';
import { Reports_custom_salesorders_ds_outbound_detail_pick_slip_reportService } from './Reports.datasource.index';
import { Reports_custom_salesorders_ds_outbound_header_pick_slip_reportService } from './Reports.datasource.index';

@Injectable({ providedIn: 'root' })
export class Reports_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public Reports: Reports_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_ds_get_order_ship_to_account_vs_order_address: Reports_custom_ds_get_order_ship_to_account_vs_order_addressService;
  public get custom_ds_get_order_ship_to_account_vs_order_address(): Reports_custom_ds_get_order_ship_to_account_vs_order_addressService {
    if(!this._custom_ds_get_order_ship_to_account_vs_order_address) {
      this._custom_ds_get_order_ship_to_account_vs_order_address = this.injector.get(Reports_custom_ds_get_order_ship_to_account_vs_order_addressService);
    }
    return this._custom_ds_get_order_ship_to_account_vs_order_address;
  }
  private _custom_ds_inventory_by_project_report: Reports_custom_ds_inventory_by_project_reportService;
  public get custom_ds_inventory_by_project_report(): Reports_custom_ds_inventory_by_project_reportService {
    if(!this._custom_ds_inventory_by_project_report) {
      this._custom_ds_inventory_by_project_report = this.injector.get(Reports_custom_ds_inventory_by_project_reportService);
    }
    return this._custom_ds_inventory_by_project_report;
  }
  private _custom_ds_invoice_get_billingtask_details: Reports_custom_ds_invoice_get_billingtask_detailsService;
  public get custom_ds_invoice_get_billingtask_details(): Reports_custom_ds_invoice_get_billingtask_detailsService {
    if(!this._custom_ds_invoice_get_billingtask_details) {
      this._custom_ds_invoice_get_billingtask_details = this.injector.get(Reports_custom_ds_invoice_get_billingtask_detailsService);
    }
    return this._custom_ds_invoice_get_billingtask_details;
  }
  private _custom_ds_invoice_get_order_details: Reports_custom_ds_invoice_get_order_detailsService;
  public get custom_ds_invoice_get_order_details(): Reports_custom_ds_invoice_get_order_detailsService {
    if(!this._custom_ds_invoice_get_order_details) {
      this._custom_ds_invoice_get_order_details = this.injector.get(Reports_custom_ds_invoice_get_order_detailsService);
    }
    return this._custom_ds_invoice_get_order_details;
  }
  private _custom_ds_invoice_get_owner_details: Reports_custom_ds_invoice_get_owner_detailsService;
  public get custom_ds_invoice_get_owner_details(): Reports_custom_ds_invoice_get_owner_detailsService {
    if(!this._custom_ds_invoice_get_owner_details) {
      this._custom_ds_invoice_get_owner_details = this.injector.get(Reports_custom_ds_invoice_get_owner_detailsService);
    }
    return this._custom_ds_invoice_get_owner_details;
  }
  private _custom_ds_invoice_header_report: Reports_custom_ds_invoice_header_reportService;
  public get custom_ds_invoice_header_report(): Reports_custom_ds_invoice_header_reportService {
    if(!this._custom_ds_invoice_header_report) {
      this._custom_ds_invoice_header_report = this.injector.get(Reports_custom_ds_invoice_header_reportService);
    }
    return this._custom_ds_invoice_header_report;
  }
  private _custom_ds_invoice_lines_report: Reports_custom_ds_invoice_lines_reportService;
  public get custom_ds_invoice_lines_report(): Reports_custom_ds_invoice_lines_reportService {
    if(!this._custom_ds_invoice_lines_report) {
      this._custom_ds_invoice_lines_report = this.injector.get(Reports_custom_ds_invoice_lines_reportService);
    }
    return this._custom_ds_invoice_lines_report;
  }
  private _custom_ds_invoice_lines_w_workorder_report: Reports_custom_ds_invoice_lines_w_workorder_reportService;
  public get custom_ds_invoice_lines_w_workorder_report(): Reports_custom_ds_invoice_lines_w_workorder_reportService {
    if(!this._custom_ds_invoice_lines_w_workorder_report) {
      this._custom_ds_invoice_lines_w_workorder_report = this.injector.get(Reports_custom_ds_invoice_lines_w_workorder_reportService);
    }
    return this._custom_ds_invoice_lines_w_workorder_report;
  }
  private _custom_ds_outbound_bol_get_carrier_details: Reports_custom_ds_outbound_bol_get_carrier_detailsService;
  public get custom_ds_outbound_bol_get_carrier_details(): Reports_custom_ds_outbound_bol_get_carrier_detailsService {
    if(!this._custom_ds_outbound_bol_get_carrier_details) {
      this._custom_ds_outbound_bol_get_carrier_details = this.injector.get(Reports_custom_ds_outbound_bol_get_carrier_detailsService);
    }
    return this._custom_ds_outbound_bol_get_carrier_details;
  }
  private _custom_ds_outbound_bol_get_loadcontainer_details: Reports_custom_ds_outbound_bol_get_loadcontainer_detailsService;
  public get custom_ds_outbound_bol_get_loadcontainer_details(): Reports_custom_ds_outbound_bol_get_loadcontainer_detailsService {
    if(!this._custom_ds_outbound_bol_get_loadcontainer_details) {
      this._custom_ds_outbound_bol_get_loadcontainer_details = this.injector.get(Reports_custom_ds_outbound_bol_get_loadcontainer_detailsService);
    }
    return this._custom_ds_outbound_bol_get_loadcontainer_details;
  }
  private _custom_ds_outbound_bol_get_pallet_details: Reports_custom_ds_outbound_bol_get_pallet_detailsService;
  public get custom_ds_outbound_bol_get_pallet_details(): Reports_custom_ds_outbound_bol_get_pallet_detailsService {
    if(!this._custom_ds_outbound_bol_get_pallet_details) {
      this._custom_ds_outbound_bol_get_pallet_details = this.injector.get(Reports_custom_ds_outbound_bol_get_pallet_detailsService);
    }
    return this._custom_ds_outbound_bol_get_pallet_details;
  }
  private _custom_ds_outbound_bol_get_shipandpymtterm_details: Reports_custom_ds_outbound_bol_get_shipandpymtterm_detailsService;
  public get custom_ds_outbound_bol_get_shipandpymtterm_details(): Reports_custom_ds_outbound_bol_get_shipandpymtterm_detailsService {
    if(!this._custom_ds_outbound_bol_get_shipandpymtterm_details) {
      this._custom_ds_outbound_bol_get_shipandpymtterm_details = this.injector.get(Reports_custom_ds_outbound_bol_get_shipandpymtterm_detailsService);
    }
    return this._custom_ds_outbound_bol_get_shipandpymtterm_details;
  }
  private _custom_ds_outbound_details_bill_of_lading_report: Reports_custom_ds_outbound_details_bill_of_lading_reportService;
  public get custom_ds_outbound_details_bill_of_lading_report(): Reports_custom_ds_outbound_details_bill_of_lading_reportService {
    if(!this._custom_ds_outbound_details_bill_of_lading_report) {
      this._custom_ds_outbound_details_bill_of_lading_report = this.injector.get(Reports_custom_ds_outbound_details_bill_of_lading_reportService);
    }
    return this._custom_ds_outbound_details_bill_of_lading_report;
  }
  private _custom_ds_outbound_details_bill_of_lading_report_advanced: Reports_custom_ds_outbound_details_bill_of_lading_report_advancedService;
  public get custom_ds_outbound_details_bill_of_lading_report_advanced(): Reports_custom_ds_outbound_details_bill_of_lading_report_advancedService {
    if(!this._custom_ds_outbound_details_bill_of_lading_report_advanced) {
      this._custom_ds_outbound_details_bill_of_lading_report_advanced = this.injector.get(Reports_custom_ds_outbound_details_bill_of_lading_report_advancedService);
    }
    return this._custom_ds_outbound_details_bill_of_lading_report_advanced;
  }
  private _custom_ds_outbound_header_bill_of_lading_report: Reports_custom_ds_outbound_header_bill_of_lading_reportService;
  public get custom_ds_outbound_header_bill_of_lading_report(): Reports_custom_ds_outbound_header_bill_of_lading_reportService {
    if(!this._custom_ds_outbound_header_bill_of_lading_report) {
      this._custom_ds_outbound_header_bill_of_lading_report = this.injector.get(Reports_custom_ds_outbound_header_bill_of_lading_reportService);
    }
    return this._custom_ds_outbound_header_bill_of_lading_report;
  }
  private _custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1: Reports_custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1Service;
  public get custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1(): Reports_custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1Service {
    if(!this._custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1) {
      this._custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1 = this.injector.get(Reports_custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1Service);
    }
    return this._custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1;
  }
  private _custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentId: Reports_custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentIdService;
  public get custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentId(): Reports_custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentIdService {
    if(!this._custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentId) {
      this._custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentId = this.injector.get(Reports_custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentIdService);
    }
    return this._custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentId;
  }
  private _custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentId_completed: Reports_custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentId_completedService;
  public get custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentId_completed(): Reports_custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentId_completedService {
    if(!this._custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentId_completed) {
      this._custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentId_completed = this.injector.get(Reports_custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentId_completedService);
    }
    return this._custom_salesorders_ds_get_non_fixed_weight_details_by_shipmentId_completed;
  }
  private _custom_salesorders_ds_get_order_account_address_by_orderId: Reports_custom_salesorders_ds_get_order_account_address_by_orderIdService;
  public get custom_salesorders_ds_get_order_account_address_by_orderId(): Reports_custom_salesorders_ds_get_order_account_address_by_orderIdService {
    if(!this._custom_salesorders_ds_get_order_account_address_by_orderId) {
      this._custom_salesorders_ds_get_order_account_address_by_orderId = this.injector.get(Reports_custom_salesorders_ds_get_order_account_address_by_orderIdService);
    }
    return this._custom_salesorders_ds_get_order_account_address_by_orderId;
  }
  private _custom_salesorders_ds_get_order_address_by_orderId_top1: Reports_custom_salesorders_ds_get_order_address_by_orderId_top1Service;
  public get custom_salesorders_ds_get_order_address_by_orderId_top1(): Reports_custom_salesorders_ds_get_order_address_by_orderId_top1Service {
    if(!this._custom_salesorders_ds_get_order_address_by_orderId_top1) {
      this._custom_salesorders_ds_get_order_address_by_orderId_top1 = this.injector.get(Reports_custom_salesorders_ds_get_order_address_by_orderId_top1Service);
    }
    return this._custom_salesorders_ds_get_order_address_by_orderId_top1;
  }
  private _custom_salesorders_ds_get_order_owner_contact_top1: Reports_custom_salesorders_ds_get_order_owner_contact_top1Service;
  public get custom_salesorders_ds_get_order_owner_contact_top1(): Reports_custom_salesorders_ds_get_order_owner_contact_top1Service {
    if(!this._custom_salesorders_ds_get_order_owner_contact_top1) {
      this._custom_salesorders_ds_get_order_owner_contact_top1 = this.injector.get(Reports_custom_salesorders_ds_get_order_owner_contact_top1Service);
    }
    return this._custom_salesorders_ds_get_order_owner_contact_top1;
  }
  private _custom_salesorders_ds_get_order_ship_to_account_vs_order_address: Reports_custom_salesorders_ds_get_order_ship_to_account_vs_order_addressService;
  public get custom_salesorders_ds_get_order_ship_to_account_vs_order_address(): Reports_custom_salesorders_ds_get_order_ship_to_account_vs_order_addressService {
    if(!this._custom_salesorders_ds_get_order_ship_to_account_vs_order_address) {
      this._custom_salesorders_ds_get_order_ship_to_account_vs_order_address = this.injector.get(Reports_custom_salesorders_ds_get_order_ship_to_account_vs_order_addressService);
    }
    return this._custom_salesorders_ds_get_order_ship_to_account_vs_order_address;
  }
  private _custom_salesorders_ds_get_order_signature_attachment: Reports_custom_salesorders_ds_get_order_signature_attachmentService;
  public get custom_salesorders_ds_get_order_signature_attachment(): Reports_custom_salesorders_ds_get_order_signature_attachmentService {
    if(!this._custom_salesorders_ds_get_order_signature_attachment) {
      this._custom_salesorders_ds_get_order_signature_attachment = this.injector.get(Reports_custom_salesorders_ds_get_order_signature_attachmentService);
    }
    return this._custom_salesorders_ds_get_order_signature_attachment;
  }
  private _custom_salesorders_ds_outbound_detail_pick_slip_report: Reports_custom_salesorders_ds_outbound_detail_pick_slip_reportService;
  public get custom_salesorders_ds_outbound_detail_pick_slip_report(): Reports_custom_salesorders_ds_outbound_detail_pick_slip_reportService {
    if(!this._custom_salesorders_ds_outbound_detail_pick_slip_report) {
      this._custom_salesorders_ds_outbound_detail_pick_slip_report = this.injector.get(Reports_custom_salesorders_ds_outbound_detail_pick_slip_reportService);
    }
    return this._custom_salesorders_ds_outbound_detail_pick_slip_report;
  }
  private _custom_salesorders_ds_outbound_header_pick_slip_report: Reports_custom_salesorders_ds_outbound_header_pick_slip_reportService;
  public get custom_salesorders_ds_outbound_header_pick_slip_report(): Reports_custom_salesorders_ds_outbound_header_pick_slip_reportService {
    if(!this._custom_salesorders_ds_outbound_header_pick_slip_report) {
      this._custom_salesorders_ds_outbound_header_pick_slip_report = this.injector.get(Reports_custom_salesorders_ds_outbound_header_pick_slip_reportService);
    }
    return this._custom_salesorders_ds_outbound_header_pick_slip_report;
  }
}

