import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_invoice_lines_w_workorder_reportService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { invoiceId: number }): 
  Promise<{ result: { Id?: number, BillingCodeId?: number, InvoiceId?: number, LineNumber?: number, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingCode?: { Id?: number, Name?: string }, BillingRecords?: { BillingTaskId?: number, InvoiceLineId?: number, BillingTask?: { WorkOrderId?: number, WorkOrder?: { LookupCode?: string, ReferenceNumber?: string } } }[], billingTask?: { Id?: number, MaterialId?: number, PackagingId?: number, ProjectId?: number, ShipmentId?: number, Packaging?: { Id?: number, DimensionUomId?: number, Length?: number, Weight?: number, WeightUomId?: number, Width?: number, WeightUom?: { Id?: number, Name?: string, Short_name?: string }, DimensionsUom?: { Id?: number, Name?: string, Short_name?: string } }, BillingContractLine?: { Id?: number, BillingAggregationStrategyId?: number, BillingAggregationStrategy?: { Id?: number, EnumName?: string } }, Project?: { LookupCode?: string, Name?: string }, order?: { OrderId?: number, ShipmentId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string } } }, AmtDue?: number, WorkOrderLookup?: string, WorkOrderReference?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.invoiceId)) {
      missingRequiredInParams.push('\'invoiceId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { invoiceId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, BillingCodeId?: number, InvoiceId?: number, LineNumber?: number, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingCode?: { Id?: number, Name?: string }, BillingRecords?: { BillingTaskId?: number, InvoiceLineId?: number, BillingTask?: { WorkOrderId?: number, WorkOrder?: { LookupCode?: string, ReferenceNumber?: string } } }[], billingTask?: { Id?: number, MaterialId?: number, PackagingId?: number, ProjectId?: number, ShipmentId?: number, Packaging?: { Id?: number, DimensionUomId?: number, Length?: number, Weight?: number, WeightUomId?: number, Width?: number, WeightUom?: { Id?: number, Name?: string, Short_name?: string }, DimensionsUom?: { Id?: number, Name?: string, Short_name?: string } }, BillingContractLine?: { Id?: number, BillingAggregationStrategyId?: number, BillingAggregationStrategy?: { Id?: number, EnumName?: string } }, Project?: { LookupCode?: string, Name?: string }, order?: { OrderId?: number, ShipmentId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string } } }, AmtDue?: number, WorkOrderLookup?: string, WorkOrderReference?: string }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.invoiceId)) {
      missingRequiredInParams.push('\'invoiceId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { invoiceId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, BillingCodeId?: number, InvoiceId?: number, LineNumber?: number, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingCode?: { Id?: number, Name?: string }, BillingRecords?: { BillingTaskId?: number, InvoiceLineId?: number, BillingTask?: { WorkOrderId?: number, WorkOrder?: { LookupCode?: string, ReferenceNumber?: string } } }[], billingTask?: { Id?: number, MaterialId?: number, PackagingId?: number, ProjectId?: number, ShipmentId?: number, Packaging?: { Id?: number, DimensionUomId?: number, Length?: number, Weight?: number, WeightUomId?: number, Width?: number, WeightUom?: { Id?: number, Name?: string, Short_name?: string }, DimensionsUom?: { Id?: number, Name?: string, Short_name?: string } }, BillingContractLine?: { Id?: number, BillingAggregationStrategyId?: number, BillingAggregationStrategy?: { Id?: number, EnumName?: string } }, Project?: { LookupCode?: string, Name?: string }, order?: { OrderId?: number, ShipmentId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string } } }, AmtDue?: number, WorkOrderLookup?: string, WorkOrderReference?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.invoiceId)) {
      missingRequiredInParams.push('\'invoiceId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
