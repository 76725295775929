import { Inject, Injectable, Injector } from '@angular/core';


import { Reports_custom_inventory_by_project_by_lot_lp_reportService } from './Reports.report.index';
import { Reports_custom_invoice_reportService } from './Reports.report.index';
import { Reports_custom_outbound_bill_of_lading_reportService } from './Reports.report.index';
import { Reports_custom_pick_slip_reportService } from './Reports.report.index';

@Injectable({ providedIn: 'root' })
export class Reports_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public Reports: Reports_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_inventory_by_project_by_lot_lp_report: Reports_custom_inventory_by_project_by_lot_lp_reportService;
  public get custom_inventory_by_project_by_lot_lp_report(): Reports_custom_inventory_by_project_by_lot_lp_reportService {
    if(!this._custom_inventory_by_project_by_lot_lp_report) {
      this._custom_inventory_by_project_by_lot_lp_report = this.injector.get(Reports_custom_inventory_by_project_by_lot_lp_reportService);
    }
    return this._custom_inventory_by_project_by_lot_lp_report;
  }
  private _custom_invoice_report: Reports_custom_invoice_reportService;
  public get custom_invoice_report(): Reports_custom_invoice_reportService {
    if(!this._custom_invoice_report) {
      this._custom_invoice_report = this.injector.get(Reports_custom_invoice_reportService);
    }
    return this._custom_invoice_report;
  }
  private _custom_outbound_bill_of_lading_report: Reports_custom_outbound_bill_of_lading_reportService;
  public get custom_outbound_bill_of_lading_report(): Reports_custom_outbound_bill_of_lading_reportService {
    if(!this._custom_outbound_bill_of_lading_report) {
      this._custom_outbound_bill_of_lading_report = this.injector.get(Reports_custom_outbound_bill_of_lading_reportService);
    }
    return this._custom_outbound_bill_of_lading_report;
  }
  private _custom_pick_slip_report: Reports_custom_pick_slip_reportService;
  public get custom_pick_slip_report(): Reports_custom_pick_slip_reportService {
    if(!this._custom_pick_slip_report) {
      this._custom_pick_slip_report = this.injector.get(Reports_custom_pick_slip_reportService);
    }
    return this._custom_pick_slip_report;
  }
}

