import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_invoice_reportServiceInParams {
  invoiceId?: number}

interface IReports_custom_invoice_reportServiceData {
  header?: { result?: { Id?: number, InvoiceDate?: string, LookupCode?: string, Notes?: string, ProjectId?: number, Project?: { Id?: number, LookupCode?: string, OwnerId?: number, Owner?: { Id?: number, Name?: string } }, owner?: { OwnerId?: number, ContactId?: number, Contact?: { Id?: number, AddressId?: number, FirstName?: string, LastName?: string, TypeId?: number, Type?: { Id?: number, Name?: string }, Address?: { Id?: number, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } } } }
  details?: { result?: { Id?: number, BillingCodeId?: number, InvoiceId?: number, LineNumber?: number, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingCode?: { Id?: number, Name?: string }, BillingRecords?: { BillingTaskId?: number, InvoiceLineId?: number, BillingTask?: { WorkOrderId?: number, WorkOrder?: { LookupCode?: string, ReferenceNumber?: string } } }[], billingTask?: { Id?: number, MaterialId?: number, PackagingId?: number, ProjectId?: number, ShipmentId?: number, Packaging?: { Id?: number, DimensionUomId?: number, Length?: number, Weight?: number, WeightUomId?: number, Width?: number, WeightUom?: { Id?: number, Name?: string, Short_name?: string }, DimensionsUom?: { Id?: number, Name?: string, Short_name?: string } }, BillingContractLine?: { Id?: number, BillingAggregationStrategyId?: number, BillingAggregationStrategy?: { Id?: number, EnumName?: string } }, Project?: { LookupCode?: string, Name?: string }, order?: { OrderId?: number, ShipmentId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string } } }, AmtDue?: number, WorkOrderLookup?: string, WorkOrderReference?: string }[] }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_invoice_reportService extends ReportBaseService<IReports_custom_invoice_reportServiceInParams, IReports_custom_invoice_reportServiceData> {

  protected reportReferenceName = 'custom_invoice_report';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_invoice_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_invoice_reportServiceInParams): Promise<IReports_custom_invoice_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_invoice_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_invoice_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        invoiceId:  $report.inParams.invoiceId 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_invoice_header_report.get(dsParams);
      
      data.header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        invoiceId:  $report.inParams.invoiceId 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_invoice_lines_w_workorder_report.get(dsParams);
      
      data.details = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
