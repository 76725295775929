import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ds_get_available_inventory_by_materialId_lotId_vendorlotIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { materialId: number, warehouseId: number, lotId?: number, vendorLotId?: number }): 
  Promise<{ result: { LocationId?: number, LotId?: number, PackagedId?: number, ActiveAmount?: number, ActivePackagedAmount?: number, AllocatedAmount?: number, AllocatedPackagedAmount?: number, AvailableAmount?: number, AvailablePackagedAmount?: number, CurrentlyAvailableAmount?: number, CurrentlyAvailablePackagedAmount?: number, ExpirationDate?: string, InactiveAmount?: number, InactivePackagedAmount?: number, IncomingAmount?: number, IncomingPackagedAmount?: number, MaterialId?: number, SoftAllocatedAmount?: number, SoftAllocatedPackagedAmount?: number, SoftIncomingAmount?: number, SoftIncomingPackagedAmount?: number, Lot?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, Location?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, Packaging?: { Id?: number, Name?: string, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialId)) {
      missingRequiredInParams.push('\'materialId\'');
    }
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { materialId: number, warehouseId: number, lotId?: number, vendorLotId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { LocationId?: number, LotId?: number, PackagedId?: number, ActiveAmount?: number, ActivePackagedAmount?: number, AllocatedAmount?: number, AllocatedPackagedAmount?: number, AvailableAmount?: number, AvailablePackagedAmount?: number, CurrentlyAvailableAmount?: number, CurrentlyAvailablePackagedAmount?: number, ExpirationDate?: string, InactiveAmount?: number, InactivePackagedAmount?: number, IncomingAmount?: number, IncomingPackagedAmount?: number, MaterialId?: number, SoftAllocatedAmount?: number, SoftAllocatedPackagedAmount?: number, SoftIncomingAmount?: number, SoftIncomingPackagedAmount?: number, Lot?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, Location?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, Packaging?: { Id?: number, Name?: string, ShortName?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialId)) {
      missingRequiredInParams.push('\'materialId\'');
    }
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { materialId: number, warehouseId: number, lotId?: number, vendorLotId?: number, $keys: { LocationId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { LocationId?: number, LotId?: number, PackagedId?: number, ActiveAmount?: number, ActivePackagedAmount?: number, AllocatedAmount?: number, AllocatedPackagedAmount?: number, AvailableAmount?: number, AvailablePackagedAmount?: number, CurrentlyAvailableAmount?: number, CurrentlyAvailablePackagedAmount?: number, ExpirationDate?: string, InactiveAmount?: number, InactivePackagedAmount?: number, IncomingAmount?: number, IncomingPackagedAmount?: number, MaterialId?: number, SoftAllocatedAmount?: number, SoftAllocatedPackagedAmount?: number, SoftIncomingAmount?: number, SoftIncomingPackagedAmount?: number, Lot?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, Location?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, Packaging?: { Id?: number, Name?: string, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialId)) {
      missingRequiredInParams.push('\'materialId\'');
    }
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
