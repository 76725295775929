import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ds_get_licenseplates_by_licenseplateIdsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { licenseplateIds: number[] }): 
  Promise<{ result: { Id?: number, Archived?: boolean, LocationId?: number, LookupCode?: string, ShipmentId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.licenseplateIds)) {
      missingRequiredInParams.push('\'licenseplateIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { licenseplateIds: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, Archived?: boolean, LocationId?: number, LookupCode?: string, ShipmentId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.licenseplateIds)) {
      missingRequiredInParams.push('\'licenseplateIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { licenseplateIds: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, Archived?: boolean, LocationId?: number, LookupCode?: string, ShipmentId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.licenseplateIds)) {
      missingRequiredInParams.push('\'licenseplateIds\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
