import { Inject, Injectable, Injector }from '@angular/core';


import { UccLabels_ds_get_measurement_unitsService } from './UccLabels.datasource.index';
import { UccLabels_ds_ucc_get_address_infoService } from './UccLabels.datasource.index';
import { UccLabels_ds_ucc_get_carrier_infoService } from './UccLabels.datasource.index';
import { UccLabels_ds_ucc_get_creation_infoService } from './UccLabels.datasource.index';
import { UccLabels_ds_ucc_get_shipment_infoService } from './UccLabels.datasource.index';
import { UccLabels_ds_ucc_get_shipment_line_infoService } from './UccLabels.datasource.index';
import { UccLabels_ds_ucc_labels_reportService } from './UccLabels.datasource.index';

@Injectable({ providedIn: 'root' })
export class UccLabels_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public UccLabels: UccLabels_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_measurement_units: UccLabels_ds_get_measurement_unitsService;
  public get ds_get_measurement_units(): UccLabels_ds_get_measurement_unitsService {
    if(!this._ds_get_measurement_units) {
      this._ds_get_measurement_units = this.injector.get(UccLabels_ds_get_measurement_unitsService);
    }
    return this._ds_get_measurement_units;
  }
  private _ds_ucc_get_address_info: UccLabels_ds_ucc_get_address_infoService;
  public get ds_ucc_get_address_info(): UccLabels_ds_ucc_get_address_infoService {
    if(!this._ds_ucc_get_address_info) {
      this._ds_ucc_get_address_info = this.injector.get(UccLabels_ds_ucc_get_address_infoService);
    }
    return this._ds_ucc_get_address_info;
  }
  private _ds_ucc_get_carrier_info: UccLabels_ds_ucc_get_carrier_infoService;
  public get ds_ucc_get_carrier_info(): UccLabels_ds_ucc_get_carrier_infoService {
    if(!this._ds_ucc_get_carrier_info) {
      this._ds_ucc_get_carrier_info = this.injector.get(UccLabels_ds_ucc_get_carrier_infoService);
    }
    return this._ds_ucc_get_carrier_info;
  }
  private _ds_ucc_get_creation_info: UccLabels_ds_ucc_get_creation_infoService;
  public get ds_ucc_get_creation_info(): UccLabels_ds_ucc_get_creation_infoService {
    if(!this._ds_ucc_get_creation_info) {
      this._ds_ucc_get_creation_info = this.injector.get(UccLabels_ds_ucc_get_creation_infoService);
    }
    return this._ds_ucc_get_creation_info;
  }
  private _ds_ucc_get_shipment_info: UccLabels_ds_ucc_get_shipment_infoService;
  public get ds_ucc_get_shipment_info(): UccLabels_ds_ucc_get_shipment_infoService {
    if(!this._ds_ucc_get_shipment_info) {
      this._ds_ucc_get_shipment_info = this.injector.get(UccLabels_ds_ucc_get_shipment_infoService);
    }
    return this._ds_ucc_get_shipment_info;
  }
  private _ds_ucc_get_shipment_line_info: UccLabels_ds_ucc_get_shipment_line_infoService;
  public get ds_ucc_get_shipment_line_info(): UccLabels_ds_ucc_get_shipment_line_infoService {
    if(!this._ds_ucc_get_shipment_line_info) {
      this._ds_ucc_get_shipment_line_info = this.injector.get(UccLabels_ds_ucc_get_shipment_line_infoService);
    }
    return this._ds_ucc_get_shipment_line_info;
  }
  private _ds_ucc_labels_report: UccLabels_ds_ucc_labels_reportService;
  public get ds_ucc_labels_report(): UccLabels_ds_ucc_labels_reportService {
    if(!this._ds_ucc_labels_report) {
      this._ds_ucc_labels_report = this.injector.get(UccLabels_ds_ucc_labels_reportService);
    }
    return this._ds_ucc_labels_report;
  }
}

