import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { Reports_homeComponent } from './Reports.home.component';
import { Reports_custom_inventory_by_project_by_lot_lp_reportComponent } from './Reports.custom_inventory_by_project_by_lot_lp_report.component';
import { Reports_custom_invoice_reportComponent } from './Reports.custom_invoice_report.component';
import { Reports_custom_outbound_bill_of_lading_reportComponent } from './Reports.custom_outbound_bill_of_lading_report.component';
import { Reports_custom_pick_slip_reportComponent } from './Reports.custom_pick_slip_report.component';


@Injectable({ providedIn: 'root' })
export class Reports_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
  ) {
    super(dialog, toastr);
  }

  public Reports: Reports_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openhome(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Reports',
        referenceName: 'Reports_home',
        component: Reports_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openhomeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Reports_homeComponent,
      'Reports',
      mode,
      dialogSize
    )
  }
  public opencustom_inventory_by_project_by_lot_lp_report(inParams:{ projectId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_inventory_by_project_by_lot_lp_report',
        referenceName: 'Reports_custom_inventory_by_project_by_lot_lp_report',
        component: Reports_custom_inventory_by_project_by_lot_lp_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_by_project_by_lot_lp_reportDialog(
    inParams:{ projectId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_inventory_by_project_by_lot_lp_reportComponent,
      'custom_inventory_by_project_by_lot_lp_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_invoice_report(inParams:{ invoiceId?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Custom Invoice Report',
        referenceName: 'Reports_custom_invoice_report',
        component: Reports_custom_invoice_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_invoice_reportDialog(
    inParams:{ invoiceId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_invoice_reportComponent,
      'Custom Invoice Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_outbound_bill_of_lading_report(inParams:{ orderId: number, shipmentId?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_outbound_bill_of_lading_report',
        referenceName: 'Reports_custom_outbound_bill_of_lading_report',
        component: Reports_custom_outbound_bill_of_lading_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_outbound_bill_of_lading_reportDialog(
    inParams:{ orderId: number, shipmentId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_outbound_bill_of_lading_reportComponent,
      'custom_outbound_bill_of_lading_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_pick_slip_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Pick Slip',
        referenceName: 'Reports_custom_pick_slip_report',
        component: Reports_custom_pick_slip_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_pick_slip_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_pick_slip_reportComponent,
      'Pick Slip',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Reports_home') {
      const title = 'Reports';
      const component = Reports_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_inventory_by_project_by_lot_lp_report') {
      const title = 'custom_inventory_by_project_by_lot_lp_report';
      const component = Reports_custom_inventory_by_project_by_lot_lp_reportComponent;
      const inParams:{ projectId: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_invoice_report') {
      const title = 'Custom Invoice Report';
      const component = Reports_custom_invoice_reportComponent;
      const inParams:{ invoiceId?: number } = { invoiceId: null };
      if (!isNil(params.get('invoiceId'))) {
        const paramValueString = params.get('invoiceId');
        inParams.invoiceId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_outbound_bill_of_lading_report') {
      const title = 'custom_outbound_bill_of_lading_report';
      const component = Reports_custom_outbound_bill_of_lading_reportComponent;
      const inParams:{ orderId: number, shipmentId?: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_pick_slip_report') {
      const title = 'Pick Slip';
      const component = Reports_custom_pick_slip_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    return result;
  }
}
