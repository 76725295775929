/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from './../environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    // the App ID representing the studio app in Azure App Registration
    clientId: '2bfb0623-57b2-4854-aa8a-2eb57efa6eae',
    // using orgainization means multi-tenant
    authority: 'https://login.microsoftonline.com/22f568f8-d546-4047-b098-832ca2dfe314',
    redirectUri: environment.authRedirectUri,
    postLogoutRedirectUri: environment.authPostLogoutRedirectUri
  },
  cache: {
    // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
    cacheLocation: BrowserCacheLocation.LocalStorage,
    // Set this to 'true' if you are having issues on IE11 or Edge
    storeAuthStateInCookie: isIE
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        // console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  }
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  backendApi: {
    endpoint: '/api',
    scopes: ['api://6ee3cbc0-fab5-441d-a151-5b5839efabd4/.default'],
  },
  backendReports: {
    endpoint: '/reports',
    scopes: ['api://6ee3cbc0-fab5-441d-a151-5b5839efabd4/.default'],
  }
};
