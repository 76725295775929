import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_inventory_by_project_reportService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { projectId: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, MaterialId?: number, TotalPackagedAmount?: number, LicensePlate?: { GrossWeight?: number, LookupCode?: string, StatusId?: number, WeightUomId?: number }, LicensePlateStatus?: { Name?: string }, Location?: { Name?: string }, Warehouse?: { Name?: string }, Packaging?: { ShortName?: string, WeightUomId?: number, WeightUom?: { Short_name?: string } }, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } }, Lot?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { projectId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, MaterialId?: number, TotalPackagedAmount?: number, LicensePlate?: { GrossWeight?: number, LookupCode?: string, StatusId?: number, WeightUomId?: number }, LicensePlateStatus?: { Name?: string }, Location?: { Name?: string }, Warehouse?: { Name?: string }, Packaging?: { ShortName?: string, WeightUomId?: number, WeightUom?: { Short_name?: string } }, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } }, Lot?: { LookupCode?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectId: number, $keys: { LicensePlateId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, MaterialId?: number, TotalPackagedAmount?: number, LicensePlate?: { GrossWeight?: number, LookupCode?: string, StatusId?: number, WeightUomId?: number }, LicensePlateStatus?: { Name?: string }, Location?: { Name?: string }, Warehouse?: { Name?: string }, Packaging?: { ShortName?: string, WeightUomId?: number, WeightUom?: { Short_name?: string } }, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } }, Lot?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
